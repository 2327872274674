import { useLingui } from "@lingui/react";
import { Outlet } from "react-router-dom";
import { styled } from "styled-components";
import { Navbar } from "../topnavi/nav-bar";
import { usePersistSearchParams } from "../utils/url";

export function PageLayout() {
  useLingui();
  usePersistSearchParams();

  return (
    <Layout>
      <Navbar />
      <Main>
        <Outlet />
      </Main>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: ${(p) => p.theme.colors.mutedNeutralBackground};

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  background-color: ${(p) => p.theme.colors.white};
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;

export const MainPanelLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${(p) => p.theme.spacing.large}px;
  padding-left: ${(p) => p.theme.spacing.medium}px;
  padding-right: ${(p) => p.theme.spacing.medium}px;
  gap: ${(p) => p.theme.spacing.medium}px;
`;

export const SidePanelLayout = styled.div<{ showBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.spacing.large}px;
  padding-left: ${(p) => p.theme.spacing.medium}px;
  padding-right: ${(p) => p.theme.spacing.medium}px;
  gap: ${(p) => p.theme.spacing.medium}px;
  min-width: 20%;
  box-sizing: border-box;
  border-right: ${(p) =>
    p.showBorder ? `1px solid ${p.theme.colors.lightNeutral}` : "none"};
`;
